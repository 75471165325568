
/* eslint-disable */
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import { ClassroomDetail, Cart } from '@/dto'
import ClassroomModel from '@/models/ClassroomModel'
import CartModel from '@/models/CartModel'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { useToast } from 'vue-toastification'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import moment from 'moment'
import ImageRating from '@/main/components/Cards/ImageRating.vue'

export default defineComponent({
  name: 'Detail Classroom',
  setup() {
    const toast = useToast()
    return {
      Auth,
      toast,
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    ImageRating,
  },
  data() {
    return {
      code: this.$route.params.code as string,
      classroom: {} as ClassroomDetail,
      showDescription: false,
      showMentor: false,
      showModalAddToCart: false,
      loadingAddToCart: false,
      showModalShare: false,
      carts: [] as Cart[],
      recommendations: [] as any,
      loadingRecommendation: false,
      openMaterials: [] as any,
      totalMaterial: 0 as number,
      reviews: [] as any,
      reviewPage: {
        page: 1,
        from: 1,
        total: 0,
        perPage: 10,
      },
      reviewRating: {} as any,
      openMaterial: [1] as any,
      openExam: false,
      showModalVideo: false,
      isIframe: false,
      linkIframe: '' as string,
      checkout: this.$route.query.checkout,
      voucher: '' as string,
      showModalVoucher: false,
      redeemStatus: false,
      loadingVoucher: false,
      voucherDetail: '' as any,

      showImageRating: false as Boolean,
      selectedImageRating: '' as string,
    }
  },
  async created() {
    await this.getClassroomDetail()
  },
  mounted() {
    this.getClassroomRecommendation()
    this.getClassroomReview()
  },
  computed: {
    getCartLoading(): boolean {
      return this.$store.getters['cartStore/getCartLoading']
    },
    getCartData(): Array<any> {
      return this.$store.getters['cartStore/getCartData']
    },
    isExistOnCart(): boolean {
      if (this.$store.getters['cartStore/getCartData'].length > 0) {
        return this.$store.getters['cartStore/getCartData'].some(
          (el: any) => el.code == this.code
        )
      } else {
        return false
      }
    },
    getUrl() {
      return window.location.href 
    },
  },
  methods: {
    isOpenMaterial(id: number) {
      return this.openMaterial.some((el: number) => el == id)
    },
    toggleMaterial(id: number) {
      if(this.openMaterials.some((el: number) => el == id)) {
        this.openMaterials.filter((el: number) => { return el != id})
      } else {
        this.openMaterials.push(id)
      }
    },
    reRender() {
      this.code = this.$route.params.code as string
      this.getClassroomDetail()
      this.getClassroomRecommendation()
      this.getClassroomReview()
    },
    toggle(id: number) {
      this.classroom.material.forEach((el: any, i: number) => {
        if(el.id == id) {
          if(el.active) {
            this.classroom.material[i].active =  false
          } else {
            this.classroom.material[i].active =  true
          }
        }
      })
    },
    async getClassroomDetail() {
      if(Auth.isLoggedIn()) {
        await ClassroomModel.detailPrivateV2(this.code)
          .then(res => {
            if (res.success) {
              if (this.checkout != 'true' && res.data.landing_page.includes('https://')) {
                window.location.href = res.data.landing_page
              } else {
                this.classroom = res.data
                if(this.classroom.material.length) {
                  let countMaterial = 0
                  this.classroom.material.forEach((el: any, i: number) => {
                    countMaterial += el.material.length
                    if(i == 0) {
                      this.classroom.material[i].active = true
                    } else {
                      this.classroom.material[i].active = false
                    }
                  })
                  this.totalMaterial = countMaterial
                }
                if (!res.data?.must_voucher) {
                  this.addToCart(this.code)
                }
              }
            } else {
              this.$router.push('/')
            }
          })
          .catch(err => {
            this.$router.push('/')
          })
      } else {
        if (this.checkout) {
          this.$router.push('/register?redirect=/classroom/' + this.code + '?checkout=true')
        } else {
          await ClassroomModel.detailPublicV2(this.code)
            .then(res => {
              if (res.success) {
                if (res.data.landing_page.includes('https://')) {
                  window.location.href = res.data.landing_page
                } else {
                  this.classroom = res.data
                  if(this.classroom.material.length) {
                    let countMaterial = 0
                    this.classroom.material.forEach((el: any, i: number) => {
                      countMaterial += el.material.length
                      if(i == 0) {
                        this.classroom.material[i].active = true
                      } else {
                        this.classroom.material[i].active = false
                      }
                    })
                    this.totalMaterial = countMaterial
                  }
                }
              } else {
                this.$router.push('/')
              }
            })
            .catch(err => {
              this.$router.push('/')
            })
        }
      }

      if (this.classroom?.url_video?.includes('iframe')) {
        var parser: any = new DOMParser()
        var parsedIframe = parser.parseFromString(this.classroom?.url_video, "text/html");
        let iFrame = parsedIframe.getElementsByTagName("iframe");
        this.linkIframe = iFrame[0].src
        this.isIframe = true
      } else {
        this.isIframe = false
      }
    },
    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },
    async buyNow() {
      if (Auth.isLoggedIn()) {
        await CartModel.addSingle(this.code)
          .then(async res => {
            if (res.success) {
              await this.getCart()
              this.$router.push('/cart?next=buy-now&course=' + this.code)
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$router.push('/register?redirect=/classroom/' + this.code)
      }
    },
    async addToCart(code: string) {
      if (Auth.isLoggedIn()) {
        await CartModel.addSingle(code)
          .then(async res => {
            if (res.success) {
              await this.getCart()
              this.showModalAddToCart = true
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        if (this.checkout) {
          this.$router.push('/register?redirect=/classroom/' + code + '?checkout=true')
        } else {
          this.$router.push('/register?redirect=/classroom/' + code)
        }
      }
    },
    closeModalAddToCart() {
      this.showModalAddToCart = false
    },
    cartTotalPriceFormat() {
      let sum = 0
      this.getCartData.forEach((el: any) => {
        sum += el.price
      })

      return sum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    toggleDescription() {
      this.showDescription = !this.showDescription
    },
    toggleMentor() {
      if (this.classroom?.mentor[0]?.description != '') {
        this.showMentor = !this.showMentor
      }
    },
    async getClassroomRecommendation() {
      this.loadingRecommendation = true
      await ClassroomModel.recommendationPublic(this.code)
        .then(res => {
          this.loadingRecommendation = false
          // console.log(res)
          if (res.success) {
            this.recommendations = res.data
            // console.log(this.recommendations[0])
          }
        })
        .catch(err => {
          console.log(err)
          this.loadingRecommendation = false
        })
    },

    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    
    isPreOrder(date: string) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if (now > this.timeZone(date)) {
        return false
      } else {
        return true
      }
    },

    priceFormatted(price: number) {
      return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },

    closeModalShare() {
      this.showModalShare = false
    },

    copyLink() {
      let url = this.$refs.link as HTMLInputElement;
      try {
        url.setAttribute('type', 'text')
        url.select()
        document.execCommand("copy")
        this.toast.success('Berhasil disalin')
      } catch (err) {
        this.toast.success('Oops, unable to copy')
      }
      url.setAttribute('type', 'hidden')
    },

    changePageReview(event: any) {
      if(this.reviewPage.page != (event.page + 1)) {
        this.reviewPage.page = event.page + 1
        this.getClassroomReview()
      }
    },
    async getClassroomReview() {
      await ClassroomModel.classroomReview(this.code, this.reviewPage.page, this.reviewPage.perPage)
        .then(res => {
          this.reviews = res.data
          this.reviewRating = res.rating
          this.reviewPage.page = res.pages.current_page
          this.reviewPage.total = res.pages.total
          this.reviewPage.from = res.pages.from 
        })
        .catch(err => {
          console.log(err)
        })
    },
    getInitialName(name: string) {
      let initials:any = name.split(' ');
  
      if(initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 1);
      }
      return initials.toUpperCase();
    },
    toHex(name: string) {
      if (name) {
        var hash = 0;
        if (name.length === 0) return hash;
        for (var i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
          hash = hash & hash;
        }
        var color = '#';
        for (var i = 0; i < 3; i++) {
          var value = (hash >> (i * 8)) & 255;
          color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
      } else {
        return '#52525b'
      }
    },
    openVideo() {
      this.showModalVideo = true
    },
    closeVideo() {
      this.showModalVideo = false
    },

    closeModalVoucher() {
      this.showModalVoucher = false
      this.voucher = ''
      this.voucherDetail = null
    },
    async redeemVoucher() {
      if (Auth.isLoggedIn()) {
        if (this.voucher) {
          this.loadingVoucher = true
          try {
            const { message, success } = await VoucherClassroomModel.redeem(
              this.voucher
            )
            if (success) {
              this.getClassroomDetail()
              this.voucherDetail = message
              this.loadingVoucher = false
              this.redeemStatus = true
              this.showModalVoucher = true
            } else {
              this.loadingVoucher = false
              this.redeemStatus = false
              this.showModalVoucher = true
            }
          } catch (err) {
            const error: any = (err as any).response.data.errors[0].message
            console.log(error)
            this.loadingVoucher = false
            this.redeemStatus = false
            this.showModalVoucher = true
          }
        }
      } else {
        this.$router.push('/register?q=daftar')
      }
    },

    openImageRating(url: string) {
      this.showImageRating = true
      this.selectedImageRating = url
    },

    closeImageRating() {
      this.showImageRating = false
      this.selectedImageRating = ''
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.code) {
        if (String(this.$route.params.code) != this.code) {
          this.reRender()
        }
      }
    },
  },
})
