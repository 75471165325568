
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImageRating',
  props: {
    show: Boolean,
    image: String,
    close: Function,
  },
})
